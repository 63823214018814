import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Spinner,
  Alert,
} from "reactstrap";
import Logo from "../../assets/logo-dark.svg";
import { loginUser } from "../../store/auth/actions";

import "./login.css";

const Login = () => {
  const { loading, error } = useSelector((state) => ({
    loading: state?.auth?.loading,
    error: state?.auth?.error,
  }));

  const [data, setData] = useState({
    email: undefined,
    pin: undefined,
  });

  const dispatch = useDispatch();

  return (
    <Container>
      <Row className="login-card align-items-center justify-content-center">
        <Col lg={5}>
          <Card className="p-4" style={{ borderRadius: "10px" }}>
            <CardBody>
              <Row className="mb-4">
                <Col lg={12}>
                  <img src={Logo} alt={"logo"} className="img-fluid" />
                  <div className="mt-2 text-muted">Review Candidate</div>
                </Col>
              </Row>
              {error ? (
                <Row>
                  <Col lg={12}>
                    <Alert color="danger">{error}</Alert>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row>
                <Col lg={12}>
                  <form>
                    <div class="form-group">
                      <label>Your email</label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            email: e.target.value,
                          })
                        }
                        class="form-control"
                        placeholder="user@example.com"
                        value={data?.email}
                        type="email"
                      />
                    </div>
                    <div class="form-group">
                      <label>Pin Code</label>
                      <input
                        onChange={(e) =>
                          setData({
                            ...data,
                            pin: e.target.value,
                          })
                        }
                        class="form-control"
                        placeholder="Enter Pin Code"
                        value={data?.pin}
                        type="password"
                      />
                    </div>
                    <div class="form-group">
                      <button
                        disabled={
                          loading
                            ? true
                            : !data?.email || !data?.pin
                            ? true
                            : false
                        }
                        onClick={() => dispatch(loginUser(data))}
                        type="button"
                        class="btn btn-primary btn-block"
                      >
                        {loading ? (
                          <Spinner size="sm" color="light" />
                        ) : (
                          "Review Candidate"
                        )}
                      </button>
                    </div>
                  </form>
                  <p className="text-center">
                    By continuing, you agree to our{" "}
                    <a
                      href="https://digitalhire.com/terms-of-service/"
                      target="_blank"
                    >
                      Terms and Services
                    </a>{" "}
                    and that you have read our{" "}
                    <a href="https://digitalhire.com/privacy-policy/">
                      Privacy Policy.
                    </a>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
