import React from 'react';

import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Login from './pages/login/Login';

import ShareInterview from './pages/shareInterview/ShareInterview';
import { useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const handleAuth = (evaluation) => {
  if (evaluation) {
    return (
      <Switch>
        <Route path="/" exact component={ShareInterview} />
        <Redirect to={"/"} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Redirect to={"/login"} />
      </Switch>
    )
  }
}

const App = () => {
  const { evaluation } = useSelector(state => ({
    evaluation: state?.auth?.evaluation
  }))
  return (
    <div className="mainPage">
      <Router>
        {handleAuth(evaluation)}
      </Router>
    </div>
  );
}

export default App;
