import React, {useEffect, useRef, useState} from "react";
import {Row, Col, Card, CardBody, Spinner} from "reactstrap";
import moment from 'moment';
import {Player, ControlBar} from 'video-react';
import "video-react/dist/video-react.css";
import Logo from "../../assets/logo-light.svg";
import {AspectRatio} from 'react-aspect-ratio';

const Questions = ({vidResume, setVidResume, evaluation}) => {
  const [question, setQuestion] = useState(undefined);
  const [answer, setAnswer] = useState([]);
  const playerRef = useRef();
  evaluation = evaluation?.InterviewReview?.Interview;
  const [playerState, setPlayerState] = useState({
    playbackRate: 1
  });

  useEffect(() => {
    console.log("DATA: ", evaluation);
    if (!question) {
      setQuestionAndAnswer(evaluation?.Position?.Question[0], evaluation?.Position?.Question[0]?.Answer);
    }
  }, [evaluation]);

  const setQuestionAndAnswer = (ques, answer) => {
    if (ques) {
      if (playerRef && playerRef?.current && ques?.type === 'video') {
        playerRef.current.load();
        playerRef.current.playbackRate = playerState.playbackRate;
      }
      console.log("QUESTION: ", ques);
      console.log("Answer: ", answer);
      setAnswer(answer[0]);
      setQuestion(ques);
      setVidResume(null);
    }
  };

  const changePlaybackRateRate = (speed) => {
    setPlayerState({
      ...playerState,
      playbackRate: speed
    });
    playerRef.current.playbackRate = speed;
  };

  const renderPagination = (questions) => {
    if (questions && questions.length > 0) {
      return questions?.map(ques => {
        return (
          <button onClick={() => setQuestionAndAnswer(ques, ques?.Answer)}
                  className={`custom-btns pagination-number ${question?.seq === ques?.seq && !vidResume ? 'bg-green text-light' : ""}`}>{ques?.seq}</button>
        );
      });
    }
  };

  const nextQuestion = () => {
    const next = evaluation?.Position?.Question?.find(ques => ques.seq === question?.seq + 1);
    console.log("NEXT DATA: ", evaluation?.Position?.Question, question?.seq);
    if (next) {
      setQuestionAndAnswer(next, next?.Answer);
      setVidResume(null);
    }
  };

  const prevQuestion = () => {
    const prev = evaluation?.Position?.Question?.find(ques => ques.seq === question?.seq - 1);
    console.log("NEXT DATA: ", evaluation?.Position?.Question, question?.seq);
    if (prev) {
      setQuestionAndAnswer(prev, prev?.Answer);
      setVidResume(null);
    }
  };

  return (
    <React.Fragment>
      <Card className="overflow-hidden mb-4">
        <CardBody className="pt-0 text-center">
          <div className="header-pagination mt-4">
            {!vidResume ? <button onClick={() => prevQuestion()} disabled={question?.seq === 1}
                                  className="btn btn-link green mr-4">Previous</button> : ""}
            {evaluation?.Interview?.Candidate?.video_resume ? <button type="button" onClick={() => {
              setVidResume(evaluation?.Interview?.Candidate?.video_resume);
              setQuestionAndAnswer(undefined);
            }} className={`custom-btns pagination-number ${vidResume ? 'bg-green text-light' : ""}`}>VR</button> : ""}
            {renderPagination(evaluation?.interviewQuestions)}
            {!vidResume ? <button onClick={() => nextQuestion()}
                                  disabled={evaluation?.Position?.Question.length === question?.seq}
                                  className="btn btn-link green ml-4">Next</button> : ""}
          </div>
          {
            !vidResume ? <Row className="justify-content-center mt-4">
              <Col className="text-center">
                <span className="d-block text-muted">{question?.seq} of {evaluation?.Position?.Question.length}</span>
                <span className="text-subtitle">{question?.title}</span>
              </Col>
            </Row> : ""
          }
          <Row className="justify-content-center mt-4">
            {
              vidResume ? <Col className="text-center">
                <div style={{textAlign: "center", border: '1px solid #000'}}>
                  <Player
                    ref={playerRef}
                    fluid={false} width={'auto'} height={350}
                  >
                    <source src={vidResume}/>
                    <ControlBar autoHide={false}/>
                  </Player>
                </div>
              </Col> : question?.type === 'video' ?
                answer?.content ?
                  <Col className="text-center">
                    <div style={{textAlign: "center", border: '1px solid #000'}}>
                      <AspectRatio ratio={0.75}
                                   style={{
                                     margin: "auto",
                                     maxHeight: "350px",
                                     backgroundSize: 'cover',
                                   }}>
                        <Player
                          ref={playerRef}
                          fluid={false} width={'auto'} height={350}
                          autoPlay
                        >
                          <source src={answer?.content}/>
                          <div
                            className="vid-overlay"
                          >
                            <img
                              src={Logo}
                              width="80"
                              alt="logo"
                            />
                          </div>
                          <ControlBar autoHide={false}/>
                        </Player>
                      </AspectRatio>
                    </div>
                  </Col> : "" : <Col className="text-center">
                  <h6>{answer?.content ? answer?.content : "Answer cannot be displayed"}</h6>
                </Col>
            }
          </Row>
          <Row className="mt-4">
            <Col lg={6} className="text-left">
              <i className="mdi mdi-calendar-blank green mr-2"></i>
              <span className="text-subtitle mr-2">Date & Time :</span>
              <span className="text-subtitle">{moment().format('MMM DD, YYYY hh:mm')}</span>
            </Col>
            {
              !vidResume ? question?.type === 'video' ? <Col lg={6} className="text-right">
                <span className="text-subtitle mr-2">Playback Speed</span>
                <button
                  className={`custom-btns speed-btn ${playerState?.playbackRate === 1 ? 'bg-green text-white' : ''}`}
                  onClick={() => changePlaybackRateRate(1)}>1x
                </button>
                <button
                  className={`custom-btns speed-btn ${playerState?.playbackRate === 1.25 ? 'bg-green text-white' : ''}`}
                  onClick={() => changePlaybackRateRate(1.25)}>1.25x
                </button>
                <button
                  className={`custom-btns speed-btn ${playerState?.playbackRate === 1.5 ? 'bg-green text-white' : ''}`}
                  onClick={() => changePlaybackRateRate(1.5)}>1.5x
                </button>
                <button
                  className={`custom-btns speed-btn ${playerState?.playbackRate === 2 ? 'bg-green text-white' : ''}`}
                  onClick={() => changePlaybackRateRate(2)}>2x
                </button>
              </Col> : "" : ""
            }
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Questions;
