import React, {useState} from 'react'
import {Col, Container, Row} from 'reactstrap'
import Profile from './Profile'
import VideoResume from './VideoResume'

import Navbar from './Navbar'
import Questions from './Questions'
import QuestionStats from './QuestionStats'
import {useSelector} from 'react-redux'
import moment from 'moment'

import './shareInterview.css'

const ShareInterview = () => {
  const [vidResume, setVidResume] = useState(undefined)

  const {evaluation} = useSelector(state => ({
    evaluation: state?.auth?.evaluation
  }))
  const [pdfResume, _] = useState(evaluation?.InterviewReview?.Interview?.Candidate?.CandidateProfile?.pdf_resume)
  console.log("D: ", pdfResume)
  return (
    <div>
      <Navbar/>
      <div className="page-content">
        <Container>
          <Row>
            <Col lg={4}>
              <Profile evaluation={evaluation}/>
              <VideoResume setVidResume={setVidResume} evaluation={evaluation}/>
              {pdfResume ? (
                <a
                  className="d-block btn btn-outline-secondary mt-2"
                  href={pdfResume ? pdfResume : ""}
                  target={`_blank`}
                >
                  PDF resume
                </a>
              ) : ""
              }
              <div className="alert alertSuccess mt-3 text-center">This link will expire
                on {moment(evaluation?.shareInterview?.expiryDate).format('MMMM DD, YYYY')}</div>
            </Col>
            <Col lg={8}>
              <Questions setVidResume={setVidResume} vidResume={vidResume} evaluation={evaluation}/>
              <QuestionStats evaluation={evaluation}/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default ShareInterview
