import { put, select, takeEvery } from "redux-saga/effects"
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginUserSuccess, logoutUserSuccess, userError } from "./actions"
import { LoginAPI } from "./authCalls"

function* loginUser({ payload: { pin, email } }) {
  try {
    console.log(pin, email)
    const result = yield LoginAPI({
      pin, email
    })
    console.log(result)
    yield put(loginUserSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(userError(err))
  }
}

function* logoutUser({ payload }) {
  try {
    yield put(logoutUserSuccess())
  } catch (err) {
    console.log(err)
    yield put(userError(err))
  }
}

// function* getInterviewAnswersVideoUrl({ payload: { questionId, key } }) {
//   try {
//     const state = yield select()
//     console.log(key)
//     const data = {
//       key: key
//     }
//     const result = yield GetAnswerVideoSignedUrlAPI(data, state)
//     console.log(result?.data?.url)
//     yield put(getAnswerVideoSuccess(questionId, result?.data?.url))
//   } catch (err) {
//     console.log(err)
//     yield put(evaluationsError(err))
//   }
// }

function* loginSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default loginSaga