import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

import LogoLight from "../../assets/logo-light.svg";
import { logoutUser } from "../../store/auth/actions";

const Navbar = () => {
  const dispatch = useDispatch();
  return (
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <Container>
        <img className="img-fluid" width="120px" src={LogoLight} alt="logo" />
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <Link
                class="nav-link"
                onClick={() => {
                  window.location.href = "https://digitalhire.com/faqs/";
                }}
                to="https://digitalhire.com/faqs/"
              >
                <i class="far green fa-question-circle mr-1"></i> <b>HELP</b>
              </Link>
            </li>
            <li class="nav-item active">
              <Link
                onClick={() => dispatch(logoutUser())}
                class="nav-link"
                to="#"
              >
                <i class="fas green fa-power-off mr-1"></i> <b>SIGN OUT</b>
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    </nav>
  );
};

export default Navbar;
