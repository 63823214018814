import api from "../apis/api";

export const LoginAPI = (body) => {
  return new Promise((res, rej) => {
    api
      .post(`/interview/review`, body)
      .then((resp) => res(resp))
      .catch((err) =>
        rej(err?.response?.data?.error || "Error, Please try again later")
      );
  });
};
