import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  GET_ANSWER_VIDEO,
  GET_ANSWER_VIDEO_SUCCESS,
  USER_ERROR
} from "./actionTypes"

export const loginUser = (data) => {
  return {
    type: LOGIN_USER,
    payload: data,
  }
}

export const loginUserSuccess = (data) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: data
  }
}

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
    payload: {},
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  }
}

export const userError = (err) => {
  return {
    type: USER_ERROR,
    payload: err
  }
}

export const getAnswerVideo = (questionId, key) => {
  return {
    type: GET_ANSWER_VIDEO,
    payload: { questionId, key }
  }
}

export const getAnswerVideoSuccess = (ques, video) => {
  return {
    type: GET_ANSWER_VIDEO_SUCCESS,
    payload: { ques, video }
  }
}