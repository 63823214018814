import React from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import videoNotAvailable from "../../assets/video-not-available.svg";
import videoModalThumbnail from "../../assets/videoModalThumbnail.PNG";
import VideoThumbnail from "react-video-thumbnail";
import { ControlBar, Player } from "video-react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const VideoResume = ({ setVidResume, evaluation, loading }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const playerRef = React.useRef(null);

  console.log(modalShow, "modalShow");
  return (
    <Card>
      <CardBody>
        {loading ? (
          <Row className="align-items-center justify-content-center">
            <Col lg={6}>
              <Spinner
                size="sm"
                className="m-auto text-center"
                color="success"
              />
            </Col>
          </Row>
        ) : (
          <Row className="align-items-center">
            <Col>
              {evaluation?.InterviewReview?.Interview?.Candidate
                ?.CandidateProfile?.video_resume ? (
                <div style={{ height: "300px", overflow: "hidden" }}>
                  {/*<VideoThumbnail*/}
                  {/*  videoUrl={evaluation?.InterviewReview?.Interview?.Candidate?.CandidateProfile?.video_resume}*/}
                  {/*  height={50}*/}
                  {/*  width={80}*/}
                  {/*/>*/}
                  {/* <Player
                      // ref={playerRef}
                      fluid={false} width={'auto'} height={300}
                    >
                      <source src={evaluation?.InterviewReview?.Interview?.Candidate?.CandidateProfile?.video_resume}/>
                      <ControlBar autoHide={false}/>
                    </Player> */}
                  <img
                    src={videoModalThumbnail}
                    alt="video thumbnail"
                    usemap="#videomap"
                  />
                </div>
              ) : (
                <div className="bg-secondary-light p-3 rounded">
                  <img
                    src={videoNotAvailable}
                    alt="promotion"
                    className="img-fluid"
                    width="100%"
                  />
                </div>
              )}
            </Col>
            {/*<Col>*/}
            {/*  <h5>Video Resume</h5>*/}
            {/*  <span className="mb-2 d-block text-muted vid-resume-text">90 Seconds Video Resume.</span>*/}
            {/*  {*/}
            {/*    evaluation?.InterviewReview?.Interview?.Candidate?.CandidateProfile?.video_resume ?*/}
            {/*      <button className="btn btn-primary"*/}
            {/*              onClick={() => setVidResume(evaluation?.InterviewReview?.Interview?.Candidate?.CandidateProfile?.video_resume)}>Watch*/}
            {/*        Video</button> : ""*/}
            {/*  }*/}
            {/**/}
            {/*</Col>*/}
          </Row>
        )}
      </CardBody>
      <map name="videomap">
        <area
          shape="rect"
          coords="14,13,93,47"
          alt="videoModalThumbnail"
          onClick={() => setModalShow(true)}
          style={{ cursor: "pointer" }}
        />
      </map>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        isOpen={modalShow}
        toggle={() => setModalShow(false)}
      >
        <ModalHeader>Video Resume</ModalHeader>
        <ModalBody>
          <Player
            ref={playerRef}
            fluid={false}
            width={"auto"}
            height={300}
            autoPlay={true}
          >
            <source
              src={
                evaluation?.InterviewReview?.Interview?.Candidate
                  ?.CandidateProfile?.video_resume
              }
            />
            <ControlBar autoHide={false} />
          </Player>{" "}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setModalShow(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default VideoResume;
