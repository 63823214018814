import React from "react";

import {Row, Col, Card, CardBody} from "reactstrap";
import moment from 'moment';

const QuestionStats = ({evaluation, loading}) => {
  console.log("QuestionStats", evaluation);
  const renderDevice = (device) => {
    if (device) return device.charAt(0).toUpperCase() + device.slice(1);
    else return 'Not found';
  };

  const handleTimeFormat = (time) => {
    var sec_num = parseInt(time, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    seconds = seconds + ' Sec';
    if (minutes) {
      minutes = minutes + ' Min';
      return minutes + ' ' + seconds;
    } else {
      return seconds;
    }
  };

  const countQuestions = (evaluation) => {
    return evaluation?.InterviewReview?.Interview?.Position?.Question.length;
  };
  const countAnswers = (evaluation) => {
    return evaluation?.InterviewReview?.Interview?.Position?.Question.filter(question => question.Answer.length > 0).length;
  };

  return (
    <Card className="overflow-hidden ques-stats">
      <CardBody className="pt-4">
        <Row className="mt-1 align-items-start text-muted">
          <Col md={5}>
            <Row className="align-items-start">
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-user-plus bx-sm mb-2"></i></span>
                  <h6>Invited</h6>
                  <span
                    className="d-block text-subtitle">{moment(evaluation?.InterviewReview?.Interview?.createdAt).format('MMM DD, YYYY')}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-comment-minus bx-sm mb-2"></i></span>
                  <h6>Completed </h6>
                  <span
                    className="d-block text-subtitle">{moment(evaluation?.InterviewReview?.Interview?.completion_date).format('MMM DD, YYYY')}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-calendar-alt bx-sm mb-2"></i></span>
                  <h6>Last updated</h6>
                  <span
                    className="d-block text-subtitle">{moment(evaluation?.InterviewReview?.Interview?.updatedAt).format('MMM DD, YYYY')}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <Row className="align-items-start">
              <Col md={3}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-comment bx-sm mb-2"></i></span>
                  <h6>Questions</h6>
                  <span className="d-block text-subtitle">{countAnswers(evaluation)}/{countQuestions(evaluation)}</span>
                </div>
              </Col>
              <Col md={3}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bxs-time bx-sm mb-2"></i></span>
                  <h6>Total Time</h6>
                  <span className="d-block text-subtitle">{handleTimeFormat(evaluation?.InterviewReview?.Interview?.response_time)}</span>
                </div>
              </Col>
              <Col md={3}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-timer bx-sm mb-2"></i></span>
                  <h6>Average Time</h6>
                  <span className="d-block text-subtitle">{handleTimeFormat(evaluation?.InterviewReview?.Interview?.avg_response_time)}</span>
                </div>
              </Col>
              <Col md={3}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bxs-devices bx-sm mb-2"></i></span>
                  <h6>Device</h6>
                  <span className="d-block text-subtitle">{renderDevice(evaluation?.device?.deviceType)}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default QuestionStats;
