import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  GET_ANSWER_VIDEO,
  GET_ANSWER_VIDEO_SUCCESS,
  USER_ERROR
} from "./actionTypes"

const initialState = {
  evaluation: undefined,
  loading: false,
  error: undefined
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: undefined }
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, evaluation: action.payload }
    case LOGOUT_USER:
      return { ...state }
    case LOGOUT_USER_SUCCESS:
      return { ...state, evaluation: null }
    case GET_ANSWER_VIDEO:
      return { ...state, loading: true, error: undefined }
    case GET_ANSWER_VIDEO_SUCCESS:
      return {
        ...state, evaluation: {
          ...state?.evaluation, interviewQuestions: state?.evaluation?.InterviewReview?.Interview?.Position?.Question.map(ques => {
            if (ques?.id === action?.payload?.ques) {
              ques.Answer.content = action.payload.video
            }
            return ques
          })
        }, loading: false
      }
    case USER_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}

export default auth
