import React from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import parsePhoneNumber from 'libphonenumber-js';

const Profile = ({evaluation}) => {
  let formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('+' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString || null;
  };

  return (
    <Card className="mb-3">
      <CardBody className="m-0 pt-0">
        <Row className="align-items-center">
          <Col sm="4">
            <div className="avatar-xl mt-4">
              <img
                src={evaluation?.InterviewReview?.Interview?.Candidate?.image}
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
          </Col>
          <Col sm="8">
            <div className="mt-4 text-muted">
              <h5
                className="text-dark mb-1">{evaluation?.InterviewReview?.Interview?.Candidate?.first_name} {evaluation?.InterviewReview?.Interview?.Candidate?.last_name}</h5>
              <span className="mb-2"><b>Position - {evaluation?.InterviewReview?.Interview?.Position?.title}</b></span>
              <span className="d-block mb-1"> <i
                class="far fa-envelope d-inline-block mr-1"></i> {evaluation?.InterviewReview?.Interview?.Candidate?.email}</span>
              <span className="d-block mb-1"> <i
                class="fas fa-phone-alt d-inline-block mr-1"></i> {evaluation?.InterviewReview?.Interview?.Candidate?.phone ? formatPhoneNumber(evaluation?.InterviewReview?.Interview?.Candidate?.phone) : "No Phone found"}</span>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Profile;
